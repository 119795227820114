import { useEffect } from "react";
import VideoSlider from "../Components/MainComponents/VideoSlider/VideoSlider";
import { Helmet } from "react-helmet";
// import WhoWeAre from "../Components/MainComponents/WhoWeAre/WhoWeAre";
// import Media from "../Components/MainComponents/Media/Media";
// import HeaderImage from "../Components/MainComponents/HeaderImage/HeaderImage";
// import Clients from "../Components/MainComponents/Clients/Clients";
// import LatestProjects from "../Components/MainComponents/LatestProjects/LatestProjects";
// import Contact from "../Components/MainComponents/Contact/Contact";

export default function Main(
  {
    // setOpen,
    // filterAllCategories,
    // filterPremieres,
    // filterLive,
    // filterPrivateParty,
    // filterProduction,
    // filterCorporate,
  }
) {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="1987 Masters delivers top-tier event production services in Los Angeles and around the world, specializing in audiovisual solutions, technical management, and creative production for corporate events, festivals, and more. Elevate your event experience globally with us. +13232034256"
        />
        <meta
          name="keywords"
          content="Global event production services Los Angeles"
        />
        <title>
          11987 Masters: Entertainment management and technology company
        </title>
      </Helmet>
      <VideoSlider />
      {/* <HeaderImage />
      <WhoWeAre
        setOpen={setOpen}
        filterCorporate={filterCorporate}
        filterAllCategories={filterAllCategories}
        filterPremieres={filterPremieres}
        filterLive={filterLive}
        filterPrivateParty={filterPrivateParty}
        filterProduction={filterProduction}
      />
      <Clients />
      <LatestProjects setOpen={setOpen} />
      <Media />
      <Contact /> */}
    </>
  );
}
