import { Helmet } from "react-helmet";

export default function News() {
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Stay updated with the latest trends and insights in event production from Los Angeles to the rest of the world with the 1987 Masters blog. Explore expert tips and industry news to enhance your event planning globally."
        />
        <meta
          name="keywords"
          content="Global event production blog Los Angeles"
        />
        <title>News</title>
      </Helmet>
      <h1>News</h1>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel repellat
        porro et beatae nam facere voluptate temporibus doloribus repudiandae,
        eius, quas nesciunt eos necessitatibus sunt magnam? Ratione vel illo
        corporis.
      </p>
    </>
  );
}
