import { useState, useMemo } from "react";
import styles from "./Header.module.css";
import Logo from "../../UI/Logo";
import BurgerMenu from "../../MobileComponents/BurgerMenu";
import { Link, useLocation } from "react-router-dom";

export default function Header({ scrollToContent }) {
  // const [showServices, setShowServices] = useState(false);
  // const [servicesClasses, setServicesClasses] = useState(
  //   `${styles.submenu} ${showServices}`
  // );
  const [showAbout, setShowAbout] = useState(false);
  const [aboutClasses, setAboutClasses] = useState(
    `${styles.about} ${styles.submenu}`
  );

  useMemo(() => {
    // showServices
    //   ? setServicesClasses(`${styles.active} ${styles.submenu}`)
    //   : setServicesClasses(`${styles.submenu}`);
    showAbout
      ? setAboutClasses(`${styles.about} ${styles.submenu} ${styles.active}`)
      : setAboutClasses(`${styles.about} ${styles.submenu}`);
  }, [showAbout]);

  const { pathname } = useLocation();

  return (
    <div className={styles.headerContainer}>
      <Logo />
      <div className={styles.burgerMenu}>
        <BurgerMenu
          pathname={pathname}
          // showServices={showServices}
          // setShowServices={setShowServices}
          showAbout={showAbout}
          setShowAbout={setShowAbout}
          // servicesClasses={servicesClasses}
          aboutClasses={aboutClasses}
        />
      </div>
      <ul className={styles.navigationMenu}>
        <Link
          style={
            pathname.includes("portfolio")
              ? { fontWeight: "500", color: "#F9A719" }
              : null
          }
          to={"/portfolio"}
        >
          PORTFOLIO
        </Link>
        <Link
          style={
            pathname.includes("services")
              ? { fontWeight: "500", color: "#F9A719" }
              : { textDecoration: "none" }
          }
          to={"services"}
          // onMouseEnter={() => setShowServices(true)}
          // onMouseLeave={() => setShowServices(false)}
        >
          SERVICES
          {/* <ul className={servicesClasses}>
            <Link to={"services"} onClick={scrollToContent}>
              CUSTOM EVENT DESIGN & PREPRODUCTION
            </Link>
            <Link to={"services/audio_tech"} onClick={scrollToContent}>
              AUDIO TECHNOLOGIES
            </Link>
            <Link to={"services/lighting"} onClick={scrollToContent}>
              LIGHTING TECHNOLOGIES
            </Link>
            <Link to={"services/video_tech"} onClick={scrollToContent}>
              VIDEO TECHNOLOGIES
            </Link>
            <Link to={"services/live_stream"} onClick={scrollToContent}>
              LIVE STREAM & CONTENT CAPTURE
            </Link>
            <Link to={"services/interview"} onClick={scrollToContent}>
              INTERVIEW AND MUSIC VIDEO PRODUCTION
            </Link>
            <Link to={"services/music_tours"} onClick={scrollToContent}>
              MUSIC TOURS TECHNICAL SUPPORT
            </Link>
            <Link to={"services/venue"} onClick={scrollToContent}>
              VENUE AV MANAGEMENT
            </Link>
            <Link to={"services/technical"} onClick={scrollToContent}>
              TECHNICAL MANAGEMENT AND EVENT SUPERVISOR
            </Link>
          </ul> */}
        </Link>
        <Link
          to={"about/our_team"}
          style={
            pathname.includes("about")
              ? { fontWeight: "500", color: "#F9A719" }
              : { textDecoration: "none" }
          }
          onMouseEnter={() => setShowAbout(true)}
          onMouseLeave={() => setShowAbout(false)}
        >
          ABOUT
          <ul className={aboutClasses}>
            <Link to={"about"}>OUR MISSION</Link>
            <Link to={"about/our_team"}>OUR TEAM</Link>
            {/* <Link to={"about/careers"}>CAREERS</Link> */}
          </ul>
        </Link>
        {/* <Link
          to={"news"}
          style={
            pathname.includes("news")
              ? { textDecoration: "underline" }
              : { textDecoration: "none" }
          }
        >
          NEWS
        </Link> */}
        <Link
          to={"contacts"}
          style={
            pathname.includes("contacts")
              ? { fontWeight: "500", color: "#F9A719" }
              : { textDecoration: "none" }
          }
        >
          CONTACTS
        </Link>
      </ul>
    </div>
  );
}
