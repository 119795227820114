import styles from "./Footer.module.css";
import Logo from "../../UI/Logo";

export default function Footer() {
  return (
    <div className={styles.footerContainer}>
      <div className={styles.footerLogo}>
        <Logo />
        <span>
          © 2016-2024 1987 Masters, Inc. Use of material is possible only with
          the permission of its owners
        </span>
        <span className={styles.falseLink}>+1 (323) 203-4256</span>
      </div>
      <div className={styles.footerLinks}>
        <div>
          <p className={styles.footerHeader}>SOCIAL MEDIA</p>
          <ul className={styles.social}>
            <li>
              <a
                href="https://www.instagram.com/1987masters?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
                target="blank_"
              >
                INSTAGRAM
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/company/1987masters/"
                target="blank_"
              >
                LINKEDIN
              </a>
            </li>
            <li>
              <a href="https://www.facebook.com/1987Masters/" target="blank_">
                FACEBOOK
              </a>
            </li>
          </ul>
        </div>
        <div>
          <p className={styles.footerHeader}>REGIONS</p>
          <div className={styles.regions}>
            <ul>
              <li>United States</li>
              <li>
                <a>Los Angeles, CA</a>
              </li>
              <li>
                <a>San Francisco, CA</a>
              </li>
              <li>
                <a>Seattle, WA</a>
              </li>
              <li>
                <a>Miami,Fl</a>
              </li>
              <li>
                <a>New York, NY</a>
              </li>
              <li>
                <a>Chicago, IL</a>
              </li>
              <li>
                <a>Boston, MA</a>
              </li>
              <li>
                <a>Philadelphia, PA</a>
              </li>
              <li>
                <a>Denver, CO</a>
              </li>
            </ul>
            <ul>
              <li>Canada</li>
              <li>
                <a>Toronto, ON</a>
              </li>
              <li>
                <a>Montreal, QC</a>
              </li>
            </ul>
          </div>
        </div>
        <div>
          <p className={styles.footerHeader}>OTHER</p>
          <ul>
            <li>
              <a href="#">TERMS OF USE</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
