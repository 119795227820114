import { useEffect } from "react";
import styles from "./Contacts.module.css";
import linkedinLogo from "../images/Contacts/linkedinLogo.svg";
import instagramLogo from "../images/Contacts/instagramLogo.svg";
import facebookLogo from "../images/Contacts/facebookLogo.svg";
import phone_icon from "../images/Contacts/phone_icon.svg";
import whatsup_icon from "../images/Contacts/whatsup_icon.svg";
import { Helmet } from "react-helmet";

export default function Contacts() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Get in touch with 1987 Masters for inquiries about our event production services in Los Angeles and across the globe. Whether you need technical support or creative solutions, we're here to assist you."
        />
        <meta
          name="keywords"
          content="Contact 1987 Masters Los Angeles global"
        />
        <title>Contacts</title>
      </Helmet>
      <div className={styles.outerContactsContainer}>
        <div className={styles.headerContacts}>
          <h2 className={styles.contactheaderText}>Contacts</h2>
          <p>Let’s get in touch</p>
        </div>
        <div className={styles.mobileHeaderText}>
          <p>Let’s get in touch</p>
        </div>
        <div className={styles.contactsContainer}>
          <div className={styles.requestServices}>
            <h3>Request Services</h3>
            <a href="mailto: Production@1987masters.com">
              Production@1987masters.com
            </a>
          </div>
          <div className={styles.partnership}>
            <h3>Partnerships</h3>
            <a href="mailto: Victoria@1987masters.com">
              Victoria@1987masters.com
            </a>
          </div>
          <div className={styles.accounting}>
            <h3>Accounting & Financial </h3>
            <a href="mailto: Invoice@1987masters.com">
              Invoice@1987masters.com
            </a>
          </div>
          <div className={styles.career}>
            <h3>Career Opportunities </h3>
            <a href="mailto: Hr@1987masters.com">Hr@1987masters.com</a>
          </div>
        </div>
        <div className={styles.socialLinksContainer}>
          <h4>Or just DM us in our social networks</h4>
          <div className={styles.socialLogos}>
            <a href="https://wa.me/13232034256" target="blank_">
              <img src={whatsup_icon} alt="whatsapp logo" />
            </a>
            <a href="tel:+13232034256" target="blank_">
              <img src={phone_icon} alt="phone logo" />
            </a>
            <a
              href="https://www.linkedin.com/company/1987masters/"
              target="blank_"
            >
              <img src={linkedinLogo} alt="linkedin logo" />
            </a>
            <a
              href="https://www.instagram.com/1987masters?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
              target="blank_"
            >
              <img src={instagramLogo} alt="instagram logo" />
            </a>
            <a href="https://www.facebook.com/1987Masters/" target="blank_">
              <img src={facebookLogo} alt="facebook logo" />
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
