import { useEffect } from "react";
import styles from "./ServicesNew.module.css";
import commonStyles from "./Contacts.module.css";
import { Link } from "react-router-dom";
import ClientsAreSaying from "../Components/ServiceComponents/ClientsAreSaying/ClientsAreSaying";
import { Helmet } from "react-helmet";

export default function ServicesNew({ setOpen }) {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  const handleOpen = () => {
    setOpen(true);
  };
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Discover the comprehensive event production services offered by 1987 Masters in Los Angeles and worldwide. From audiovisual equipment rental to full event management, we provide everything you need for a successful event anywhere in the world."
        />
        <meta
          name="keywords"
          content="Global event production services Los Angeles"
        />
        <title>Services</title>
      </Helmet>
      <div className={styles.servicesOuter}>
        <div className={commonStyles.headerContacts}>
          <h2>Services</h2>
        </div>
        <div className={styles.servicesContent}>
          <div className={styles.servicesSubheader}>
            <h2>
              TURNKEY SOLUTIONS FROM <span>WOW-IDEA</span> T0 SOLID PRODUCTION
            </h2>
          </div>
          <div className={styles.servicesTable}>
            <div className={styles.servicesSell}>
              <div className={styles.sellHeader}>
                <h3>Venue or client representation</h3>
              </div>
              <ul>
                <li>Consulting on al aspects of the event</li>
                <li>Production service upsell for venues</li>
                <li>
                  Quite/invoice optimization according the budget for client
                </li>
                <li>Artists’ riders negotiation</li>
                <li>Venue crew training and education</li>
              </ul>
            </div>

            <div className={styles.servicesSell}>
              <div className={styles.sellHeader}>
                <h3>Venue AV Management and Operations</h3>
              </div>
              <ul>
                <li>Venue Representation and AV Sales</li>
                <li>Custom AV Packages and Add-ons</li>
                <li>Custom Quotes and Estimates</li>
                <li>
                  Tech Specification Documents, CAD and Engineering Plans{" "}
                </li>
                <li>Event Pre-production and Planning</li>
                <li>Project and Production Management</li>
                <li>Event Logistics and Coordination</li>
                <li>Crew Management and Scheduling</li>
                <li>Onsite Technical Support</li>
                <li>House Equipment Service and Maintenance</li>
                <li>Oversight Vendors to Follow Venue Rules and Policies</li>
              </ul>
            </div>

            <div className={styles.servicesSell}>
              <div className={styles.sellHeader}>
                <h3>Event management and hospitality</h3>
              </div>
              <ul>
                <li>Hospitality supervision and logistics</li>
                <li>Venue scouting and selection</li>
                <li>Permitting</li>
                <li>Security</li>
                <li>Staffing</li>
                <li>
                  Food and beverage, furniture, floral vendors coordination
                </li>
                <li>
                  VIP guests red carpet reception and event experience
                  assistance
                </li>
                <li>On-site talent and influencers management</li>
                <li>
                  Coordination with various client team stakeholders, vendors,
                  partners
                </li>
              </ul>
            </div>

            <div className={styles.servicesSell}>
              <div className={styles.sellHeader}>
                <h3>Technical Management and Event Supervision</h3>
              </div>
              <ul>
                <li>Client Representation</li>
                <li>Custom AV Packages and Add-ons</li>
                <li>Quotes and Estimates Comparison</li>
                <li>Tech Specification Documents, CAD and Engineering Plans</li>
                <li>Event Pre-production and Planning</li>
                <li>Project and Production Management</li>
                <li>Event Logistics and Coordination</li>
                <li>Crew Management and Scheduling</li>
                <li>Onsite Technical Support</li>
                <li>Subrentals Source, Pickup/Delivery</li>
                <li>Oversight Vendors and Venue Crew</li>
              </ul>
            </div>

            <div className={styles.servicesSell}>
              <div className={styles.sellHeader}>
                <h3>Custom event design and pre-production</h3>
              </div>
              <ul>
                <li>Creative concept and preproduction</li>
                <li>CADs, floor plans and previsuals</li>
                <li>Video content and graphics</li>
                <li>Production schedule and run of the show</li>
                <li>Crew scheduling and management</li>
                <li>Transportation logistics</li>
                <li>Technical supervision</li>
                <li>Production management</li>
                <li>Audio, video and lighting technology</li>
                <li>Equipment rentals</li>
                <li>Content capture, editing and postproduction</li>
              </ul>
            </div>

            <div className={styles.servicesSell}>
              <div className={styles.sellHeader}>
                <h3>Music Tours Technical Support</h3>
              </div>
              <ul>
                <li>Tour Planning and Pre-production</li>
                <li>Logistics, Engineering and Project Management</li>
                <li>Venue Coordination</li>
                <li>Production Management</li>
                <li>Onsite Technical Support</li>
                <li>Crew Scheduling</li>
              </ul>
            </div>

            <div className={styles.servicesSell}>
              <div className={styles.sellHeader}>
                <h3>Entertainment and booking A-list artists</h3>
              </div>
              <ul>
                <li>
                  Music performance by A-list artists: Christina Aguilera,
                  Beyoncé, Billie Eilish, Justin Bieber, Jennifer Lopez, Harry
                  Styles, Cirque du Soleil etc.
                </li>
              </ul>
            </div>

            <div className={styles.servicesSell}>
              <div className={styles.sellHeader}>
                <h3>Interview and Music Video Production</h3>
              </div>
              <ul>
                <li>Concept Development</li>
                <li>Scriptwriting</li>
                <li>Pre-production planning</li>
                <li>Filming</li>
                <li>Post-production Editing</li>
                <li>Audio and Visual Effects</li>
              </ul>
            </div>
            <div className={styles.servicesButtonContainer}>
              <Link className={styles.linkAbout} to={"/about"}>
                ABOUT
              </Link>
              <Link className={styles.quot} onClick={handleOpen}>
                GET THE QUOTE
              </Link>
            </div>
          </div>

          <div className={styles.servicesSubheader}>
            <h2>We create events for the perfect experience</h2>
          </div>

          <div className={styles.servicesTable}>
            <div className={styles.servicesSell}>
              <div className={styles.sellHeader}>
                <h3>Innovative technologies powered by Al</h3>
              </div>
              <ul>
                <li>Volumetrics screens</li>
                <li>Virtual reality (VR)</li>
                <li>Augmented reality (AR)</li>
                <li>Mixed reality (XR)</li>
                <li>
                  3D mapping and other digital innovations that enhance the
                  physical experience
                </li>
                <li>Cinema DCP Projection with Dolby Surround Audio</li>
              </ul>
            </div>

            <div className={styles.servicesSell}>
              <div className={styles.sellHeader}>
                <h3>Audio Technologies</h3>
              </div>
              <ul>
                <li>
                  Audio System Design and Engineering, SPL Coverage Simulation
                </li>
                <li>
                  5.1, 7.1 and Atmos Dolby Surround Audio Systems for Movie
                  Premiers and Screenings
                </li>
                <li>PA Audio Systems for Music and Corporate Shows</li>
                <li>FOH and Monitor Mixing</li>
                <li>Waves Plug-in Integration</li>
                <li>Wireless Systems RF Management</li>
                <li>Backline Services</li>
                <li>Multitrack Recording and Playback</li>
              </ul>
            </div>

            <div className={styles.servicesSell}>
              <div className={styles.sellHeader}>
                <h3>Lighting Technologies</h3>
              </div>
              <ul>
                <li>Lighting Systems Design</li>
                <li>Lighting Plot and Visual Simulation</li>
                <li>Intelligent and Conventional Lighting Systems</li>
                <li>Lighting Control</li>
                <li>Special FX and Lasers</li>
              </ul>
            </div>

            <div className={styles.servicesSell}>
              <div className={styles.sellHeader}>
                <h3>Video Technologies</h3>
              </div>
              <ul>
                <li>Video Systems Design</li>
                <li>Projection Mapping</li>
                <li>DCP Video Playback</li>
                <li>LED Screens</li>
                <li>Multisource Switching</li>
                <li>Video Playback and Recording</li>
                <li>PowerPoint and Keynote Presentations</li>
              </ul>
            </div>

            <div className={styles.servicesSell}>
              <div className={styles.sellHeader}>
                <h3>Live Stream & Content Capture</h3>
              </div>
              <ul>
                <li>Live Video Streaming and Webcast</li>
                <li>
                  Zoom, Teams, YouTube, Vimeo, Facebook, Instagram, TikTok, vMix
                </li>
                <li>Multi-camera Setup</li>
                <li>Post-production Editing</li>
                <li>iMAG</li>
              </ul>
            </div>
            <div
              className={`${styles.servicesButtonContainer} ${styles.servicesContact}`}
            >
              <Link to={"/contacts"} className={styles.quot}>
                CONTACTS
              </Link>
            </div>
          </div>

          <div className={styles.servicesSubheader}>
            <h2>
              Let’s collaborate and bring your project to life! Tell us about
              your vision and we’ll make it happen!
            </h2>
          </div>
        </div>
        <ClientsAreSaying />
      </div>
    </>
  );
}
